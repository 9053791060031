import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AddressesPathProvider, ApiUrlProvider, LoginDataProvider, OrganizationProvider } from '@as/api';
import { AuthService } from '../common/auth';
import { AuthConstants } from '../common/auth/auth-constants.enum';
import { AuthStorageService } from '../common/auth/auth-storage-service';
import { AppConfigurationService } from '../modules/commons/configurations/services/app-configuration.service';
import { NotificationService } from '../modules/commons/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ApiDataProviderService implements ApiUrlProvider, OrganizationProvider, LoginDataProvider, AddressesPathProvider {
  constructor(
    private readonly configService: AppConfigurationService,
    private readonly authService: AuthService,
    private readonly authStore: AuthStorageService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
  ) {}

  public path(): string {
    return '/assets/addresses/addresses.json';
  }

  public organizationId(): string {
    return this.authService.getOrganizationId();
  }

  public apiUrl(): string {
    const value = this.configService.configuration.apiUrl;
    if (!value) {
      throw new Error('Missing API url in configuration');
    }

    return value;
  }

  public accessToken(): string | null {
    return this.authStore.getJwt();
  }

  public setAccessToken(token: string): void {
    this.authStore.setJwt(token);
  }

  public refreshToken(): string | null {
    return this.authStore.getRefreshToken();
  }

  public setRefreshToken(token: string): void {
    this.authStore.setRefreshToken(token);
  }

  public navigateToLogout(): void {
    this.router.navigate(['/auth/login']).then(() => {
      this.notificationService.info(`AUTH.logoutError:message`);
    });
  }

  public applicationToken(): string {
    return AuthConstants.TOKEN_HOST;
  }

  public clearUserData(): void {
    this.authStore.clearTokens();
  }
}
